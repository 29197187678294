import React, { Fragment } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Badge, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import { Utils, Loading, DeleteCourse, ListAvailableCourse } from "custom";
import { CourseAPI, TeacherAPI } from "api";
import Select from "react-select";
import { Link } from "react-router-dom";
import CreateCouse from '../../../pages/Course/CreateCourse';

const moment = require("moment")

class ListCourse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            loading: true,
            error: false,
            teachers: [],
            selectedTeachers: {},
            showEdit:false,
            form: {
                id:  null,
                name:  null,
                author:  null,
                enabled: null,
            },
        };
    }

    componentDidMount() {
        this.getCourses();
        this.getTeachers()
    }

    getCourses() {
        CourseAPI.list().then((courses) => {
            this.setState({ error: false, loading: false, courses: courses });
        }).catch(() => {
            this.setState({ error: true, loading: false });
        })
    }

    setSelectTeacher(val) {
        this.setState({ selectedTeachers: val })
    }

    showModalAvailableCourse() {
        global.onSetModal({
            title: "LISTA CORSI DISPONIBILI",
            content: <div>
                <ListAvailableCourse onResolve={this.getCourses.bind(this)} />
            </div>,
            isOpen: true,
            toggle: true,
            size: 'xl',
            type: 'info',
            buttons: [
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => global.onSetModal({ isOpen: false })
                },
            ]
        })
    }

    getTeachers = () => {
        if (Utils.getUserData().role === "ADMIN") {
            this.setState({ loading: true });
            TeacherAPI.list().then((teachers) => {
                this.setState({ loading: false, error: false, teachers: teachers });
            }).catch(() => {
                this.setState({ error: true });
            })
        }
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form});
    }

    saveCourse = () => {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (self.state.selectedTeachers == null)
            error.teacher = "Il campo docente è obbligatorio";

        // if (form.enabled === '')
        //     error.enabled = "Il campo autore è obbligatoria";
        // if (form.author === '')
        //     error.author = "Il campo autore è obbligatoria";
        console.log('error',error);
        this.setState({ errors: error });

        if (Object.keys(error).length === 0) {
            global.onSetModal({ loading: true });
            const payload = {
                ...form,
                id_teacher: self.state.selectedTeachers.id
            }
            CourseAPI.edit(payload).then(() => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'Corso modificato',
                    text: 'Il corso è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                })
                this.setState({showEdit:false})
                self.getCourses();
                // .then(function (result) {
                //     if (result.value) {
                //         self.getCourses();

                //         global.onSetModal({ isOpen: false, loading: false, content: "" })
                //     }
                // })
                // this.setState({selectedTeachers:null})
            }).catch((err) => {
                console.log(err)
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la modifica del corso',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        let errors = this.state.errors;
        let form = this.state.form
        let self = this
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista corsi.</Alert>
            </React.Fragment>
        }


        return (this.state.loading) ? <Loading /> : <React.Fragment>


            <Row className="g-2">
                <Col sm={4}>
                    <div className="search-box">
                        {/*<Input type="text" className="form-control" placeholder="Search for name or designation..." onChange={(e) => searchList(e.target.value)} />*/}
                        {/*<i className="ri-search-line search-icon"></i>*/}
                    </div>
                </Col>
                <Col className="col-sm-auto ms-auto">
                    <div className="list-grid-nav hstack gap-1">

                        {(Utils.getUserData().role === "ADMIN") ? <CreateCouse onResolve={this.getCourses.bind(this)} /> : null}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Row className="team-list grid-view-filter">
                        {(this.state.courses.length === 0) ? <div className="py-4 mt-4 text-center">
                            <img src={require("../../../assets/icons/book-outline.gif")}
                                style={{ width: "72px", height: "72px" }} alt=''/>
                            <h5 className="mt-4">Purtroppo nessun corso è stato trovato</h5>
                        </div> : this.state.courses.map((course, i) => {
                            return <Col key={i}>
                                <Card className="team-box">
                                    <div className="team-cover">
                                        <img src={require("../../../assets/images/background-de.jpg")} alt="" />
                                    </div>
                                    <CardBody className="p-4">
                                        <Row className="align-items-center team-row">
                                            {(Utils.getUserData().role === "ADMIN") ? <Col className="team-settings">
                                                <Row>
                                                    <Col>
                                                        {/*    <div className="flex-shrink-0 me-2">*/}
                                                        {/*        <button type="button"*/}
                                                        {/*                className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn"*/}
                                                        {/*                onClick={(e) => favouriteBtn(e.target)}>*/}
                                                        {/*            <i className="ri-star-fill fs-14"></i>*/}
                                                        {/*        </button>*/}
                                                        {/*    </div>*/}
                                                    </Col>
                                                    <UncontrolledDropdown direction='start' className="col text-end">
                                                        <DropdownToggle tag="a" role="button">
                                                            <i className="ri-more-fill fs-17"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                        <DropdownItem
                                                            className="dropdown-item edit-list"
                                                            onClick={() => {
                                                                this.setState({showEdit:true, form:course, selectedTeachers: course.author})
                                                            }}
                                                        >
                                                            <i className="ri-pencil-line me-2 align-bottom text-muted"></i>Modifica
                                                        </DropdownItem>
                                                            {/* <GestCourse
                                                                preset={course}
                                                                teachers={this.state.teachers}
                                                                onResolve={this.getCourses.bind(this)}
                                                                setSelectTeacher={this.setSelectTeacher.bind(this)}
                                                                selectedTeachers={this.state.selectedTeachers}
                                                            /> */}
                                                            {/* <DeleteCourse id={course.id} onResolve={this.getCourses.bind(this)} /> */}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </Row>
                                            </Col> : null}
                                            <Col lg={4} className="col">
                                                <div className="team-profile-img">
                                                    <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                                        <img src={require((course.id === 18) ? "../../../assets/images/logo_swiss.jpg" : "../../../assets/images/avatar-cltv.png")} alt="" className="img-fluid d-block rounded-circle" />
                                                    </div>
                                                    <div className="team-content">
                                                        <h5 className="fs-16 mb-1">{course.name}</h5>
                                                        <p className="text-muted mb-0">{(course.author !== null) ? course.author.firstname + " " + course.author.lastname : "NON ASSEGNATO"}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} className="col">
                                                {/*<Row className="text-muted text-center">*/}
                                                {/*    <Col xs={6} className="border-end border-end-dashed">*/}
                                                {/*        <h5 className="mb-1">{item.projectCount}</h5>*/}
                                                {/*        <p className="text-muted mb-0">Projects</p>*/}
                                                {/*    </Col>*/}
                                                {/*    <Col xs={6}>*/}
                                                {/*        <h5 className="mb-1">{item.taskCount}</h5>*/}
                                                {/*        <p className="text-muted mb-0">Tasks</p>*/}
                                                {/*    </Col>*/}
                                                {/*</Row>*/}
                                            </Col>
                                            <Col lg={2} className="col">
                                                <div className="text-end">
                                                    <Link to={"/course/" + course.id}
                                                        className="btn btn-light view-btn">Accedi
                                                        al corso</Link>
                                                </div>
                                                {(["ADMIN", "TEACHER"].includes(Utils.getUserData().role)) ? <span>
                                                    <br />
                                                    <div className="text-center">
                                                        <span>{(!course.enabled) ? <small>CORSO NON ABILITATO</small> : null}</span>
                                                    </div>
                                                </span> : null}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        })
                        }
                    </Row>
                </Col>
            </Row>
            <Modal style={{ overflowY: "auto" }} isOpen={this.state.showEdit} scrollable={true} className={'modal-dialog-scrollable modal-lg '}>
                <ModalBody className='px-sm-5 pt-5 pb-50'>
                    <div className='text-center mb-5'>
                        <h3 className='mb-1'>MODIFICA CORSI</h3>
                    </div>
                    <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <Input type="text" value={form.name}
                                invalid={Boolean(errors?.name)}
                                onChange={(e) => self.changeForm('name', e.target.value)} />
                            <FormFeedback>{errors?.name}</FormFeedback>
                        </div>
                    </Col>

                    <Col md="12">
                        <div className='mb-2'>
                            <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                                <Input className="form-check-input" type="checkbox" role="switch" checked={form.enabled}
                                    onChange={(e) => self.changeForm('enabled', e.target.checked)} />
                                <Label className="form-check-label">Attiva / Disattiva Corso</Label>
                            </div>
                        </div>
                    </Col>
                    <Col md="12">
                    <div className='mb-4'>
                            <Label className='form-label'>
                                Seleziona Insegnante
                            </Label>
                            <Select
                                value={self.state.selectedTeachers}
                                onChange={(obj) => {
                                    // self.changeForm('teacher', obj)
                                    self.setSelectTeacher(obj)
                                }}
                                options={self.state.teachers}
                                placeholder='Seleziona Insegnante'
                                getOptionLabel={(opt) => opt.firstname + ' ' + opt.lastname}
                                getOptionValue={(opt) => opt.firstname + ' ' + opt.lastname}
                            />
                            <Input invalid={Boolean(errors?.teacher)} hidden/>
                            <FormFeedback>{errors?.teacher}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='success'
                        onClick={this.saveCourse}
                    >
                        SALVA
                    </Button>
                    <Button
                        color='danger'
                        onClick={() => this.setState({
                            showEdit: false,
                            form: {
                                id:  null,
                                name:  null,
                                author:  null,
                                enabled: null,
                            },
                            selectedTeachers: null
                        })}
                    >
                        CHIUDI
                    </Button>
                </ModalFooter>
            </Modal>

        </React.Fragment>
    }
}

export default ListCourse;
